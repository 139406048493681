<template>
    <div class="card">
        <div class="bg-blue-light p-1">
            <TitleButton
                title="Re-order Level Report"
                btnTitle="Add new"
                :showBtn="false"
            />
        </div>
        <div class="my-2 px-2">
            <DateQuerySetter
                btnTitle="Go"
                @onClickGo="onClickGo"
                :isHideDate="true"
            >
                <div class="col-12 col-sm-6 col-md-4 col-lg-4 mr-top-1">
                    <label class="form-label">Manufacturer</label>
                    <v-select
                        placeholder="Select manufacturer"
                        label="name"
                        :options="manufacturers"
                        v-model="manufacturer"
                        :reduce="text => text.value"
                    />
                </div>
            </DateQuerySetter>
        </div>

        <div class="col-12 px-2 mt-2 mb-4">
            <ListTable
                :tableItems="tableItems"
            />
        </div>

        <div class="px-2 position-absolute bottom-0 mt-4">
            <Pagination :offset="params.offset" @onPageChange="onPageChange" ref="pagination"/>
        </div>

        <Loader v-if="loading"/>
    </div>
</template>

<script setup>
import TitleButton from '@/components/atom/TitleButton';
import { inject, onMounted, reactive, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import Loader from '@/components/atom/LoaderComponent'
import Pagination from '@/components/atom/Pagination'
import ListTable from '@/components/molecule/company/hospital/pharmacy/ReorderReportTable.vue';
import AsyncSelect from "@/components/molecule/input-field/AsyncSelect.vue";
import DateQuerySetter from '@/components/atom/DateQuerySetter.vue';
import handleHospital from '@/services/modules/hospital'

const store = useStore();
const route = useRoute();
const router = useRouter();
const showError = inject('showError');
const showSuccess = inject('showSuccess');
const loading = ref(false)
const tableItems = ref([]);
const params = reactive({
    searchTxt: '',
    offset: 10
});

const manufacturers = ref([]);
const manufacturer = ref(null);

const { fetchReorderReport, fetchManufacturer } = handleHospital();

const setPaginationData = (data) => {
    store.commit('setPaginationData', data)
};

const setPagination = (data) => {
    setPaginationData({
        records: data.total,
        perPage: data.per_page,
        currentPage: data.current_page,
    })
}

async function onPageChange(page) {
    let routeQuery = Object.assign({}, route.query)
    routeQuery.page = page
    await router.push({path: route.path, query: routeQuery})
    await getReorderReport();
}

const onClickGo = () => {
    getReorderReport()
}

const getReorderReport = async () => {
    const query = getQuery();
    loading.value = true

    await fetchReorderReport(query).then(res => {
        loading.value = false
        if (!res.status){
            store.commit("resetPagination")
            return
        }
        tableItems.value = res.data.data;
        setPagination(res.data)
    }).catch(err => {
        console.log(err)
    }).finally(() => {
        loading.value = false
    })
}

const getManufacturers = async () => {
    await fetchManufacturer(getQuery()).then(res => {
        if (!res.status) return
        manufacturers.value = res.data
    }).catch(err => {
        console.log(err)
    })
}

const getQuery = () => {
    let query = `?company_id=${route.params.companyId}`;
    if (route.query.page) query += '&page=' + route.query.page
    if(params.searchTxt) query += `&search_txt=${params.searchTxt}`;
    if(manufacturer.value) query += `&manufacturer=${manufacturer.value}`;
    query += '&offset=' + params.offset
    return query;
}

onMounted(async () => {
    await getManufacturers();
    await getReorderReport();
})
</script>

<style scoped>
.mr-top-1 {
    margin-top: 1% !important;
}
</style>